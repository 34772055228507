import React from "react";

const Programa = () => {
    return (

        <div id="programa">
            <div class="bg-slate-600 pb-1"></div>
           <div class='sm:hidden pt-16 bg-white'>
                <div class="">
                    <div class=" pb-16">
                        <h2 class="text-3xl px-3 text-center font-medium tracking-tight rounded-full py-1 box-decoration-box bg-sky-950 text-white sm:text-3xl">Programa</h2>
                        <div class="px-12 pt-10">
                        </div>
                        <div class=" bg-gradient-to-t from-slate-400 to-white">
                        <div class="flex flex-col pt-10">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div>
                                            <h3 class="text-center pb-8">Horario de las actividades</h3>
                                        </div>
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Jueves 21 Noviembre </th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Viernes 22 Noviembre</th>
                                                <th scope="col" class="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase">Sabado 23 Noviembre</th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200">
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">9:00 - 10:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Inauguración e inscripciones</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td class="px-3 py-3 text-center text-sm"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">10:00 - 11:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia: <br /><small class='uppercase text-sky-600'>AAPT-MX Historia, orígenes, logros, retos y desafíos</small></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia: <br /><small class='uppercase text-sky-600'>Introducción al Aprendizaje Activo en óptica y Fotónica (ALOP)</small></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia: <br /> <small class='uppercase text-sky-600'>La Física, el preámbulo de la Educación Matemática</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">11:00 - 12:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Presentación de libro <br /><small class="text-sky-600 text-xs">Nuevas perspectivas de la investigación en física educativa</small></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia: <br /> <small class='uppercase text-sky-600'>EL DESARROLLO DE COMPETENCIAS A TRAVÉS DE LA ENSEÑANZA DE LAS CIENCIAS</small></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia: <br /> <small class='uppercase text-sky-600'>Inteligencia artificial como herramienta de evaluación en aula invertida</small></td>
                                                </tr>
                                                <tr class="bg-lime-100">
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">12:00 - 12:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">12:30 - 14:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias simultaneas</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias simultaneas</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencias simultaneas</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">14:00 - 14:30</td>
                                                <td rowspan="2" class="px-3 py-3 text-center text-sm text-gray-800">Talleres 1</td>
                                                <td rowspan="2" class="px-3 py-3 text-center text-sm text-gray-800">Talleres 2</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">14:30 - 16:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Asamblea General y Salida a Museo</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">16:30 - 17:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Receso</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td rowspan="3" class="px-3 py-3 text-center text-sm text-gray-800">Paseo Santa Lucia</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">17:00 - 18:00</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800">Ponencia:<br /> <small class='uppercase text-sky-600'>Involucrar a Los Estudiantes en Clase y Virtualmente con Clases Demostrativas Interactivas (ILDs)</small> </td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>

                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">18:00 - 19:00</td>
                                                <td rowspan="2"  class="px-3 py-3 text-center text-sm text-gray-800">Coctel de Bienvenida</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-3 text-center text-sm font-medium text-gray-800">19:00 - 19:30</td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                <td class="px-3 py-3 text-center text-sm text-gray-800"></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div class='pt-14 pb-20 hidden sm:flex bg-gradient-to-t from-slate-400 to-white '>
                <div class="pt-14 w-9/12 mx-auto">
                    <div class=" text-center justify-center items-center">
                        <h2 class="text-4xl font-medium tracking-tight box-decoration-box rounded-full py-2 bg-sky-950 text-white sm:text-5xl">Programa</h2>
                    </div>
                    <div class="pb-4">
                        <div>
                            <h3 class="text-center pt-14 pb-8">Horario de las actividades</h3>
                        </div>
                        <div>
                            <div class="flex flex-col">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-500 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col"  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" colspan="4" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-orange-200">Jueves 21 Noviembre </th>
                                                <th scope="col" colspan="2" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-yellow-200">Viernes 22 Noviembre</th>
                                                <th scope="col" colspan="1" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-green-200">Sabado 23 Noviembre</th>
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-400">
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">9:00 - 10:00</td>
                                                <td colspan="4" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Inauguración e inscripciones<br /><small>Audiovisual A</small></td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100"></td>
                                                <td class="px-3 py-4 text-center text-xs bg-green-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">10:00 - 11:00</td>
                                                <td colspan="4" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencia: <br /> <small class="text-sky-600 text-xs uppercase">AAPT-MX Historia, orígenes, logros, retos y desafíos</small><br /><small >por el Dr. César Eduardo Mora Ley<br /></small></td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencia estilo Minitaller:<br></br> <small class="text-sky-600 text-xs uppercase">Introducción al Aprendizaje Activo en óptica y Fotónica (ALOP)</small><br></br><small class="text-xs">por el Dr. David R. Sokoloff</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Ponencia: <br /> <small class="text-sky-600 text-xs uppercase">La Física, el preámbulo de la Educación Matemática</small><br /><small >por el Dr. Ricardo Pulido Ríos <br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">11:00 - 12:00</td>
                                                <td colspan="4" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Presentación de libro <br /><small class="text-sky-600 text-xs">Nuevas perspectivas de la investigación en física educativa</small><br /><small >Coordinadores: Dr. Jesus Alberto Flores Cruz y el Dr. Rubén Sánchez-Sánchez<br /></small></td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencia: <br /> <small class="text-sky-600 text-xs">EL DESARROLLO DE COMPETENCIAS A TRAVÉS DE LA ENSEÑANZA DE LAS CIENCIAS</small><br /><small >por la Dra. Nivia Tomasa Álvarez Aguilar<br /></small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Ponencia: <br /> <small class="text-sky-600 text-xs uppercase">Inteligencia artificial como herramienta de evaluación en aula invertida</small><br /><small>por la Dra. Lizbeth Habib Mireles<br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:00 - 12:30</td>
                                                <td colspan="7" class="px-3 py-4 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:40 - 14:00</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">Educación STEM y Aprendizaje Activo</small><br /><small>Aulas 300 salón A</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">Investigación educativa y experiencias docentes</small><br /><small>Aulas 300 salón B</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">Divulgación de la física y experiencias de laboratorio</small><br /><small>Aulas 300 salón C</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">Inteligencia artificial generativa en la enseñanza de la física</small><br /><small>Auditorio Virtual 1</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">STEM y Escenarios para la enseñanza de la física y retos en la formación docente </small> <br /><small></small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencias Simultaneas <br /> <small class="text-sky-600 text-xs">Educación STEM</small> <br /><small>Auditorio</small></td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Ponencias<br /> <small class="text-sky-600 text-xs">Divulgación y experiencias docentes</small><br /><small>Auditorio Virtual 1</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:00 - 14:30</td>
                                                <td colspan="4" rowspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Talleres 1</td>
                                                <td colspan="2" rowspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Talleres 2</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:30 - 16:30</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Asamblea General y Salida a Museo</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:00 - 18:00</td>
                                                <td colspan="4" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencias <br /> <small class="text-sky-600 text-xs">Investigación educativa y experiencias docentes</small><br /><small>Auditorio</small></td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencias <br /> <small class="text-sky-600 text-xs">Divulgación de la física y experiencias de laboratorio</small><br /><small>Auditorio</small></td>
                                                <td rowspan="3" class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Paseo Santa Lucia</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">18:00 - 19:00</td>
                                                <td colspan="4" rowspan="2"  class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Coctel de Bienvenida</td>
                                                <td colspan="2" rowspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">19:00 - 19:30</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="pt-12 w-9/12 mx-auto">
            <div>
                    <h3 class="text-center pb-12">Horario detallado de las ponencias</h3>
                </div>
                    <div class="pb-20">
                        <div>
                            <div class="flex flex-col">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-500 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col"  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" colspan="5" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-orange-200">Jueves 21 Noviembre </th>
                                               </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-400">
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">9:00 - 10:00</td>
                                                <td colspan="5" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Inauguración e inscripciones<br /><small>Audiovisual A</small></td>
                                                
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">10:00 - 11:00</td>
                                                <td colspan="5" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Ponencia: <br /> <small class="text-sky-600 text-xs uppercase">AAPT-MX Historia, orígenes, logros, retos y desafíos</small><br /><small >por el Dr. César Eduardo Mora Ley<br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">11:00 - 12:00</td>
                                                <td colspan="5" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Presentación de libro <br /><small class="text-sky-600 text-xs">Nuevas perspectivas de la investigación en física educativa</small><br /><small >Coordinadores: Dr. Jesus Alberto Flores Cruz y el Dr. Rubén Sánchez-Sánchez<br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:00 - 12:20</td>
                                                <td colspan="5" class="px-3 py-4 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800">Lugar</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Aulas 300 Salón A</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Aulas 300 Salón B</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Aulas 300 Salón C</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Auditorio Virtual</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Por definir</td>
                                                </tr>
                                                
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:20 - 12:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Mejorando la educación STEM a través de la comunicación de la ciencia: Una revisión general” <br /> <small class="text-sky-600">Autores: Jesús Manuel Sáenz Villela, Karen Yael Castrejón Parga</small><br /><small>Universidad Autónoma de Ciudad Juárez</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“La física de los pulmones propuesta didáctica para la toma de decisiones”<br /> <small class="text-sky-600">Autores: Fabiola Escobar Moreno, Rubén de Jesús Tovilla Quesada</small><br /><small>CICATA Legaria del Instituto Politécnico Nacional, CECyT No. 2 del Instituto Politécnico Nacional</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Experiencias de divulgación de la física en la Huasteca Potosina”<br /> <small class="text-sky-600">Autores: Carmen del Pilar Suarez Rodríguez</small><br /><small>Coordinación Académica Región Huasteca Sur, UASLP</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Sesgos de inteligencias artificiales generativas de imágenes al representar ambientes de aprendizaje en física”<br /> <small class="text-sky-600">Autores: Vicente Torres Zúñiga</small><br /><small>Escuela Nacional de Ciencias Forenses, Universidad Nacional Autónoma de México</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:40 - 13:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Uso de los laboratorios STEM como indicador en el aumento del índice de aprobación en los cursos de física para el nivel medio superior”<br /> <small class="text-sky-600">Autores: Andres González García, Luis David Valadez Ponce, Gerardo Valadez Ponce, et., al.</small><br /><small>Universidad de Guanajuato, Escuela de Nivel Medio Superior Centro Histórico León <br /> Universidad de Guanajuato, Escuela de Nivel Medio Superior de Silao</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Estrategias de desarrollo del pensamiento metacognitivo en el planteamiento de contenidos de física en estudiantes de primaria”<br /> <small class="text-sky-600">Autores: Mtra. Claudia Araceli Patlán Martínez, Dr. Mario Humberto Ramírez Diaz, Dr. Francisco Antonio Horta Rangel.</small><br /><small>Instituto Politécnico Nacional</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Compartiendo experiencias de uso de simuladores PHET en el laboratorio de mecánica de fluidos de la FI, UNAM”<br /> <small class="text-sky-600">Autores: Dr. Arnulfo Ortiz Gómez</small><br /><small>Departamento de Ciencias Aplicadas de la División de Ciencias Básicas, Facultad de Ingeniería, UNAM.</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Uso de BRISK Teaching en un curso-taller de Física Cuántica”<br /> <small class="text-sky-600">Autores: Palomares Mendoza José Guadalupe</small><br /><small>Universidad de Guadalajara</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:00 - 13:20</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Desarrollo de habilidades metacognitivas mediante experimentos en un curso-taller de Mecánica”<br /> <small class="text-sky-600">Autores: Barragán Zepeda Aránzazu, Palomares Mendoza José Guadalupe</small><br /><small>Universidad de Guadalajara</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Entendimiento conceptual de las interacciones dentro de la electricidad y el magnetismo como conceptos paralelos”<br /> <small class="text-sky-600">Autores: Eder Hernández, Esmeralda Campos, Pablo Barniol, Genaro Zavala.</small><br /><small>Instituto para el Futuro de la Educación, Tecnológico de Monterrey, Escuela de Ingeniería y Ciencias, Tecnológico de Monterrey, Facultad de Ingeniería, Universidad Andrés Bello, Santiago, Chile</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Actualización del plan de estudio por el modelo educativo de la nueva escuela mexicana y rediseño del manual de prácticas física I de bachillerato en la Universidad de Colima”<br /> <small class="text-sky-600">Autores: M. Ed. Abel Ignacio Garnica Marmolejo, M. Ed. Verónica Vázquez Ramírez</small><br /><small>Universidad de Colima</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Integración de la Realidad Aumentada en la Enseñanza de la Geometría Espacial: Un Enfoque Innovador para Mejorar la Comprensión en Modelación de Problemas de física”<br /> <small class="text-sky-600">Autores: Miguel Olvera Aldana, José Gilberto Castrejón Mendoza, Mario Humberto Ramírez Díaz.</small><br /><small>Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada del Instituto Politécnico Nacional (IPN)</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:20 - 13:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Implementación de realidad virtual 3D para el aprendizaje activo en ingeniería eléctrica: un enfoque integral de educación STEM”<br /> <small class="text-sky-600">Autores: Cesar David Ramírez, Agustín López Sánchez, Jesús Alberto Flores Cruz</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Investigación educativa y experiencias docente Divulgación de la ciencia”<br /> <small class="text-sky-600">Autores: Guadalupe Carrillo, Mónica Arellano, Alicia Rizo, Jessica Domínguez, Silvia Maffey</small><br /><small>CECyT 2 Miguel Bernard. Instituto Politécnico Nacional</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Divulgación de la física y experiencias de laboratorio. Experimento del informe sobre la teoría relativista de la gravitación de 1919; efecto de la desviación de la luz por la curvatura del espacio-tiempo producida por un cuerpo masivo”<br /> <small class="text-sky-600">Autores: Francisco Hernández-Cabrera, Alberto Paulo Méndez-Dettmer Muñoz, Edgar Iván Hinojosa Saldaña, María Fernanda Díaz Garza.</small><br /><small>Facultad de Ciencias Físico-Matemáticas, Universidad Autónoma de Nuevo León, San Nicolás de los Garza, México</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Aplicación de inteligencia artificial en la evaluación educativa: innovaciones para la enseñanza de la física”<br /> <small class="text-sky-600">Autores: Jorge Álvarez Ramírez, Jorge Iván Hidalgo Reyes, Pedro Francisco Hernández Pérez</small><br /><small>Tecnológico de Monterrey</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:40 - 14:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Aprendizaje activo para la ecuación de calor de una varilla metálica con apoyo de registros semióticos para la educación superior”<br /> <small class="text-sky-600">Autores: Rubén Sánchez Sánchez, César Mora Ley, Elvia Rosa Ruiz Ledezma.</small><br /><small>Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada. Unidad Legaria del Instituto Politécnico Nacional</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“FEM inducida en una bobina montada en un péndulo rígido que oscila en un campo magnético”<br /> <small class="text-sky-600">Autores: Daniel Blanco Cintrón, Luis Navarrete, Mario Flores Pérez, Jaime Francisco Almaguer Medina.</small><br /><small>Departamento de Física, CUCEI, Universidad de Guadalajara</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100"></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Fortalecimiento de competencias en física a través de la realidad virtual: el caso TecDrone”<br /> <small class="text-sky-600">Autores: Jorge Iván Hidalgo Reyes, Jorge Álvarez Ramírez, Pedro Francisco Hernández Pérez</small><br /><small>Tecnológico de Monterrey</small></td>
                                                
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:00 - 14:30</td>
                                                <td colspan="5" rowspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Talleres 1</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:30 - 16:30</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800">Lugar</td>
                                                <td colspan="5" class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-200">Auditorio</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:20 - 16:20</td>
                                                <td colspan="5" class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Metodología para la olimpiada mexicana de física”<br /> <small class="text-sky-600 text-xs">Autor: Noveron Figueroa Francisco Roberto</small><br /><small>Colegio Superior Agropecuario del Estado de Guerrero</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:20 - 17:00</td>
                                                <td colspan="5" class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Percepciones de empleadores, profesores y estudiantes de física acerca de la física educativa”<br /> <small class="text-sky-600 text-xs">Autores: José Luis Santana Fajardo[1], Liliana Vázquez Mercado[2], María Elena Rodríguez Pérez[3]</small><br /><small>[1,2] Departamento de Física, Centro Universitario de Ciencias Exactas e Ingenierías, Universidad de Guadalajara [3] Centro de Estudios e Investigaciones del Comportamiento, Centro Universitario de Ciencias Biológico Agropecuarias, Universidad de Guadalajara.</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">17:20 - 17:40</td>
                                                <td colspan="5" class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“Integración de modelado 3D, simulación y realidad virtual en la enseñanza de intercambiadores de calor”<br /> <small class="text-sky-600 text-xs">Autores: César García Díaz, Dumar Camacho Luengas, Josefina Castillo Reyna, Elvia Sánchez Rodríguez</small><br /><small>Instituto Tecnológico y de Estudios Superiores de Monterrey</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">17:40 - 18:00</td>
                                                <td colspan="5" class="px-3 py-4 text-justify text-xs text-gray-800 bg-orange-100">“FEM inducida en una bobina montada en un péndulo rígido que oscila en un campo magnético.”<br /> <small class="text-sky-600 text-xs">Autores: Daniel Blanco Cintrón, Luis Navarrete, Mario Flores Pérez, Jaime Francisco Almaguer Medina.</small><br /><small>Departamento de Física, CUCEI, Universidad de Guadalajara</small></td>
                                                </tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">18:00 - 19:00</td>
                                                <td colspan="5" rowspan="2"  class="px-3 py-4 text-center text-xs text-gray-800 bg-orange-100">Coctel de Bienvenida</td>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">19:00 - 19:30</td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <div class="pt-8 w-9/12 mx-auto">
                    <div class="pb-20">
                        <div>
                            <div class="flex flex-col">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-500 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col"  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" colspan="2" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-yellow-200">Viernes 22 Noviembre </th>
                                               </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-400">
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">10:00 - 11:00</td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencia estilo Minitaller:<br /> <small class="text-sky-600 text-xs uppercase">Introducción al Aprendizaje Activo en óptica y Fotónica (ALOP)</small><br /><small >por el Dr. David R. Sokoloff<br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">11:00 - 12:00</td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Ponencia:<br /><small class="text-sky-600 text-xs">EL DESARROLLO DE COMPETENCIAS A TRAVÉS DE LA ENSEÑANZA DE LAS CIENCIAS</small><br /><small >por la Dra. Nivia Tomasa Álvarez Aguilar</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:00 - 12:20</td>
                                                <td colspan="2" class="px-3 py-4 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800">Lugar</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-200">Por definir</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-200">Auditorio</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:20 - 12:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“A Study on the Influence of the Affective Domain on the Attitudes of Middle School Students toward Mathematics from a Gender Perspective”<br /> <small class="text-sky-600">Autores: Mariana Gutiérrez-Aguilar and Santa Tejeda</small><br /><small>Special Issue Gender and STEM Education</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“La Física sin límites: inspirando a las nuevas generaciones”<br /> <small class="text-sky-600">Autores: Dr.C. Juana María Despaigne Bombus</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:40 - 13:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“La evolución del pensamiento crítico mediante estrategias filosóficas para la enseñanza de la dualidad onda-partícula”<br /> <small class="text-sky-600">Autores: Jesús Diego Tuero-O ́Donnell Zulaica[1], Gilberto Castrejón[2]</small><br /><small>[1] Universidad Pontificia Bolivariana, Colombia. [2] Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada, Unidad Legaria, México</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Desarrollo de habilidades metacognitivas mediante experimentos en un curso-taller de Mecánica”<br /> <small class="text-sky-600">Autores: Barragán Zepeda Aránzazu, Palomares Mendoza José Guadalupe</small><br /><small>Universidad de Guadalajara</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:00 - 13:20</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Enseñanza de física en la educación preescolar bajo el enfoque de la nueva escuela mexicana”<br /> <small class="text-sky-600">Autores: Mario Humberto Ramírez Díaz, Carlos Israel Aguirre Vélez</small><br /><small>Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada Unidad Legaria, Instituto Politécnico Nacional.</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Actividades extracurriculares STEM en la escuela primaria”<br /> <small class="text-sky-600">Autores: Manuela Paz Bolaños, Johan Manuel Zúñiga Collazos</small><br /><small>Universidad Autónoma Chapingo,Dpto. de Preparatoria Agrícola, Área de Física</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:20 - 13:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“El interés en la física en la comunidad normalista”<br /> <small class="text-sky-600">Autores: Cynthia Zamora Pedraza[1], Carmen del Pilar Suarez Rodríguez[2]</small><small>[1] Escuela Normal de Estudios Superiores del Magisterio Potosino, [2] Coordinación Académica Región Huasteca Sur, UASLP</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Videos tutoriales interactivos para el desarrollo de habilidades de resolución de problemas numéricos”<br /> <small class="text-sky-600">Autores: Beatriz Oropeza Villalobos[1], Mario Humberto Ramírez Díaz[2]</small><br /><small>[1] Instituto de Educación Media Superior de la Ciudad de México, [2] Centro de Investigación en Ciencia Aplicada y Tecnología Avanzada, unidad Legaria, IPN</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:40 - 14:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Inteligencia artificial generativa en el diseño de problemas de física”<br /> <small class="text-sky-600">Autores: Víctor Edrei Robles-Chávez[1],[2], Mario Humberto Ramírez-Díaz[1]</small><br /><small>[1] Instituto Politécnico Nacional, [2] Instituto Tecnológico de Estudios Superiores de Monterrey</small></td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Un sistema que resuelve problemas relacionados con el efecto Doppler”<br /><small class="text-sky-600">Autores: Guillermo Becerra Córdova</small><br /><small>Universidad Autónoma Chapingo,Dpto. de Preparatoria Agrícola, Área de Física</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:00 - 14:30</td>
                                                <td colspan="2" rowspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-100">Talleres 2</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:30 - 16:30</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800">Lugar</td>
                                                <td colspan="2" class="px-3 py-4 text-center text-xs text-gray-800 bg-yellow-200">Auditorio</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:20 - 16:20</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Interacción de campos magnéticos con sistemas biológicos: una experiencia de laboratorio en el marco de la educación STEM”<br /> <small class="text-sky-600 text-xs">Autores: Arias Diosdado Alan, Horta Rangel Antonio, Gutiérrez Ortega Norma, Quiros Rodríguez Israel, Noriega Alvarado Jorge</small><br /><small>División de Ingenierías, Campus Guanajuato</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:20 - 16:40</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Experimentos simples y atractivos sobre óptica geométrica, combinación de colores y aberración cromática utilizando elementos tecnológicos”<br /> <small class="text-sky-600 text-xs">Autores: Uriel Rivera-Ortega</small><br /><small>Universidad Tecnológica de Puebla</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:40 - 17:00</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Diseño de objetos tridimensionales para fomentar el proceso de enseñanza-aprendizaje en estudiantes de nivel medio superior utilizando un ventilador holográfico”<br /> <small class="text-sky-600 text-xs">Autores: M.M. Arellano, A.M. Rizo, M.G. Carrillo</small><br /><small>Instituto Politécnico Nacional</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">17:00 - 17:20</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Tecnologías emergentes como herramienta didáctica para promover la ciencia en el nivel preescolar”<br /> <small class="text-sky-600 text-xs">Autores: M.M. Arellano, A.M. Rizo, M.M. Carrillo</small><br /><small>Instituto Politécnico Nacional</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">17:20 - 17:40</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Diseño de un caso ingenieril y su aplicación por el método del caso para aprender principios de física”<br /> <small class="text-sky-600 text-xs">Autores: Luis Jorge Benítez Barajas, José Gilberto Castrejón Mendoza, Fabiola Escobar Moreno</small><br /><small>Instituto Politécnico Nacional México Cicata Legaria</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">17:40 - 18:00</td>
                                                <td colspan="2" class="px-3 py-4 text-justify text-xs text-gray-800 bg-yellow-100">“Aproximación al fenómeno de la luminiscencia por medio de recursos de divulgación científica”<br /> <small class="text-sky-600 text-xs">Autores: Silvia G. Maffey García, Jessica G. Domínguez Muñoz, Guadalupe Carrillo Alejo, Alejandro S. Rivera Montalvo, Karla J, Soto Hernández.</small><br /><small>Instituto Politécnico Nacional.</small></td>
                                                </tr> 
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
                <div class="pt-8 w-9/12 mx-auto">
                    <div class="pb-20">
                        <div>
                            <div class="flex flex-col">
                                <div class="-m-1.5 overflow-x-auto">
                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                        <div class="border overflow-hidden rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-500 bg-white">
                                            <thead>
                                                <tr>
                                                <th scope="col"  class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Hora</th>
                                                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase bg-green-200">Sabado 23 Noviembre </th>
                                               </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-400">
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">10:00 - 11:00</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Ponencia:<br /> <small class="text-sky-600 text-xs uppercase">La Física, el preámbulo de la Educación Matemática</small><br /><small >por el Dr. Ricardo Pulido Ríos <br /></small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">11:00 - 12:00</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Ponencia:<br /><small class="text-sky-600 text-xs">Inteligencia artificial como herramienta de evaluación en aula invertida</small><br /><small >por la Dra. Lizbeth Habib Mireles</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:00 - 12:20</td>
                                                <td class="px-3 py-4 text-center text-sm text-gray-800">Receso</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800">Lugar</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-200">Auditorio Virtual</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:20 - 12:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-green-100">“Determinación de la Densidad de la Sangre”<br /> <small class="text-sky-600">Autores: José Manuel López Rodríguez, Juan Tapia mercado, Alma Rocío Cavazos Marín, Jesús Lerma Aragón</small><br /><small>Facultad de Ciencias, Campus Punta Morro, Universidad Autónoma de Baja California</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">12:40 - 13:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-green-100">“Plataformas 2D y 3D para el desarrollo de materiales académicos de la física matemáticas a nivel medio superior y superior”<br /> <small class="text-sky-600">Autores: Miguel Ángel Martínez Martínez</small><br /><small>Facultad de Ciencias Físico Matemáticas - Universidad Autónoma de Nuevo León</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:00 - 13:20</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-green-100">“Estilos de aprendizaje (Felder-Silverman) para la enseñanza de las leyes de Newton”<br /> <small class="text-sky-600">Autores: Manuel Sandoval Martínez</small><br /><small>TecNM. Campus Comalcalco</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:20 - 13:40</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-green-100">“Desafíos y Oportunidades de la IA en la Enseñanza de la Física: Un primer acercamiento”<br /> <small class="text-sky-600">Autores: Drte. Orville Heloín Trujillo Narváez Rivera</small><small>Universidad Autónoma de Baja California Sur (UABCS) Instituto Tecnológico de La Paz (ITLAP)</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">13:40 - 14:00</td>
                                                <td class="px-3 py-4 text-justify text-xs text-gray-800 bg-green-100">“Desarrollo del pensamiento científico en física: conocimiento empírico y científico”<br /> <small class="text-sky-600">Autores: Guillermina Ávila García</small><br /><small>Instituto Politécnico Nacional, Centro de Estudios Científicos y Tecnológicos No. 11</small></td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">14:30 - 16:30</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Asamblea General y Salida a Museo</td>
                                                </tr>
                                                <tr>
                                                <td class="px-3 py-4 text-center text-xs font-medium text-gray-800">16:30 - 19:30</td>
                                                <td class="px-3 py-4 text-center text-xs text-gray-800 bg-green-100">Paseo Santa Lucia</td>
                                                
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>

            
            
        </div>


    )
}
export default Programa;