import React from "react";
import image from './images/apptmx_logo.png';
import image_logo_oficial from './images/LOGO OFICIAL.jpg';

const Convocatoria = () => {
    return (

        <div id="convocatoria">
            <div class=' sm:hidden pt-4 bg-gradient-to-t from-blue-950 to-black'>
                <div class="pt-14">
                    <div class="pt-6 pb-6 text-center bg-orange-100">
                        <p className="text-lg text-black">Aviso</p>   
                        <p className="text-sm pt-2 text-black">Los mejores trabajos presentados serán invitados para una evaluación por pares ciegos el cual podrá ser parte de un capitulo de libro (ISBN) editado por el autor el Dr. Juan Carlos Ruiz Mendoza </p>       
                    </div> 
                    <div class="pt-12">
                        
                        <div class="px-12 text-justify bg-white">
                        </div>   

                        <h2 class="text-3xl px-3 text-center font-bold tracking-tight text-white sm:text-3xl">American Association of Physics Teachers Section Mexico 2024</h2>
                        <div class="px-12 pt-10">
                            <img src={image_logo_oficial} class="sm:hidden rounded-lg object-contain shadow"></img>
                            
                        </div>
                        <div class="px-12 text-justify">
                            <p className="text-lg px-3 pt-11 text-white">¡Bienvenidos a la página de la Reunión Anual de la  AAPT-MX 2024!</p>
                            <p className="text-lg px-3 pt-2 text-white ">Es un placer darles la más cordial bienvenida a la página oficial del evento AAPT-MX 2024, donde la ciencia, la tecnología, la ingeniería y las matemáticas se unen en Monterrey este año. Este emocionante evento reúne a maestros, estudiantes y expositores apasionados por estas disciplinas en un ambiente dinámico y enriquecedor.</p>
                            <p className="text-lg px-3 pt-2 text-white">La AAPT-MX 2024 es mucho más que un simple evento; es una experiencia educativa interactiva diseñada para explorar los fascinantes aspectos de la ciencia, la tecnología, la ingeniería y las matemáticas a través de una variedad de actividades, talleres y conferencias impartidas por expertos en la materia.</p>
                            <p className="text-lg px-3 pt-3 text-white">Únanse a nosotros mientras exploramos los misterios del universo, desde la mecánica cuántica hasta la astrofísica, y descubrimos cómo la ciencia y la tecnología impactan en nuestras vidas cotidianas. Prepárense para sumergirse en un mundo de descubrimiento y aprendizaje mientras nos embarcamos juntos en este apasionante viaje por las fronteras de la ciencia, la tecnología, la ingeniería y las matemáticas.</p>
                            <p className="text-lg px-3 pb-20 pt-14 text-white">¡No se pierdan la oportunidad de ser parte de la reunión anual AAPT-MX 2024 y disfrutar de las distintas conferencias y talleres en Monterrey este año!</p>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div class='hidden sm:flex justify-center pt-6 bg-orange-100'>
                <p className='text-lg text-black bg-white rounded-lg px-2 outline-1 outline'>Aviso</p>   
            </div>
            <div class='hidden sm:flex pb-6 justify-center bg-orange-100'>
                <p className='text-sm pt-2 text-black'>Los mejores trabajos presentados serán invitados para una evaluación por pares ciegos el cual podrá ser parte de un capitulo de libro (ISBN) editado por el autor el Dr. Juan Carlos Ruiz Mendoza</p>       
            </div> 
            <div class=' hidden sm:flex pt-14 bg-gradient-to-t from-blue-950 to-black'>
                <div class="pt-8 w-1/2 mx-auto">
                    <div class="px-2 text-center">
                        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl">American Association of Physics Teachers Section Mexico 2024</h1>
                        <div class="flex items-center justify-center pt-12">
                            <img src={image_logo_oficial} class="sm:flex rounded-lg shadow object-cover max-w-sm"></img>
                        </div>
                        
                        
                    </div>
                    <div class="text-justify">
                        <p className="text-lg pt-14 text-white">¡Bienvenidos a la la página de la Reunión Anual de la AAPT-MX 2024!</p>
                        <p className="text-lg pt-3 text-white">Es un placer darles la más cordial bienvenida a la página oficial del evento AAPT-MX 2024, donde la ciencia, la tecnología, la ingeniería y las matemáticas se unen en Monterrey este año. Este emocionante evento reúne a maestros, estudiantes y expositores apasionados por estas disciplinas en un ambiente dinámico y enriquecedor.</p>
                        <p className="text-lg pt-3 text-white">La AAPT-MX 2024 es mucho más que un simple evento; es una experiencia educativa interactiva diseñada para explorar los fascinantes aspectos de la ciencia, la tecnología, la ingeniería y las matemáticas a través de una variedad de actividades, talleres y conferencias impartidas por expertos en la materia.</p>
                        <p className="text-lg pt-3 text-white">Únanse a nosotros mientras exploramos los misterios del universo, desde la mecánica cuántica hasta la astrofísica, y descubrimos cómo la ciencia y la tecnología impactan en nuestras vidas cotidianas. Prepárense para sumergirse en un mundo de descubrimiento y aprendizaje mientras nos embarcamos juntos en este apasionante viaje por las fronteras de la ciencia, la tecnología, la ingeniería y matemáticas.</p>
                        <p className="text-lg pb-20 pt-12 text-white">¡No se pierdan la oportunidad de ser parte de la reunión anual AAPT-MX 2024 y disfrutar de las distintas conferencias y talleres en Monterrey este año!</p>
                    </div>
                </div>
            </div>
        </div>
        


    )
}
export default Convocatoria